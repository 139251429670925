.login-form {
    background: linear-gradient(to bottom, #0568eb 0%, #0568eb 40%, #bad8ff 100% );
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}

.container1 {
    position:relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-width: 320px;
    background-color: #ffffff;
    border-radius: 20px;
    height: 500px;
}

.login-form h2 {
    padding-top: 10%;
    padding-bottom: 30px;
    font-size: 50px;
    color: #333;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

label.email,
label.password {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 40px;
}

.emailBar,
.passwordBar {
    padding-left: 10px;
    font-size: medium;
    margin-top: 5px;
    border-radius: 5px;
    border-style: solid;
    border-color: rgb(186, 186, 186);
    padding-top: 10px;
    padding-bottom: 10px;
}

.signupBtn {
    margin-top: 25px;
    border: none;
    background: none;
    font-size: large;
    font-weight: bold;
    color: #0568eb;
    cursor: pointer;
}

.signupBtn:active {
    transform: translateY(4px);
}

.loginBtn {
    margin-top: 60px;
    margin-inline: 20px;
    background-color: #0568eb;
    color: #ffffff;
    padding: 15px;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.loginBtn:active {
    transform: translateY(4px);
    background-color: #004aab;
    box-shadow: #004aab;
}

.moodleLoginBtn {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-inline: 20px;
    background-color: #ffa600;
    color: #ffffff;
    padding: 15px 30px;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.moodleLoginBtn:active {
    transform: translateY(4px);
    background-color: #a96e00;
}

.filler {
    display: block;
    height: 50px;
}

.or {
    margin-top: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}