
.footer-container {
  background-color: #84CCF8;
  color: #fff;
  padding: 20px;
  text-align: center;
  /* Use flex properties to position the footer at the bottom */
  position: absolute;
  bottom: 0;
  width: 100%;
}
  
  .footer-text {
    font-family: 'League Spartan', sans-serif; /* Apply League Spartan font */
    font-size: 16px; /* Adjust font size as needed */
  }
  