/* QR Reader styles */
.qr-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
  }
  
  .square-button {
    background-color: #86CEF8; /* Green background color */
    color: white; /* White text color */
    padding: 10px 20px; /* Add padding */
    font-size: 16px; /* Increase font size */
    border: none; /* Remove border */
    border-radius: 5px; /* Add border radius */
    cursor: pointer; /* Add cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
    font-family: 'League Spartan', sans-serif; /* Use League Spartan font */
    font-weight: 600;
  }
  
  .square-button:hover {
    background-color: #86CEF8; /* Darker green on hover */
  }
  
  .plus-icon {
    margin-left: 5px; /* Add some space between text and icon */
    font-size: 20px; /* Increase icon size */
  }
