
  .bar-indicator {
    width: 100%;
    height: 5px;
    background-color: #D9D9D9;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .bar {
    height: 100%;
    border-radius: 5px;
  }